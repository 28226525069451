import React from 'react'
import Helmet from 'react-helmet'
import { graphql, PageProps } from 'gatsby'

import Layout from '../../layouts'
import HeaderText from '../../components/HeaderText'
import HeaderWrapper from '../../components/HeaderWrapper'

interface TravelPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

const TravelPage: React.FC<TravelPageProps> = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>{`Travel | ${data.site.siteMetadata.title}`}</title>
      </Helmet>
      <HeaderWrapper>
        <HeaderText>{'Travel'}</HeaderText>
        <h2>{'Travel is good for the soul.'}</h2>
      </HeaderWrapper>
    </Layout>
  )
}

export default TravelPage
